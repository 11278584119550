import classNames from "classnames";
import styles from "./ChooseTheme.module.scss";
import buttonStyles from "../components/SelectorButton.module.scss";
import { SelectorButton } from "../components/SelectorButton";
import { Cards, ScreenSwitchDelay } from "../config/Config";
import { useAppState } from "../stores/useAppState";
import { ButtonBack } from "../components/ButtonBack";
import { ButtonNext } from "../components/ButtonNext";
import ReactGA from "react-ga";

export const ChooseStyle = () => {
  const appState = useAppState();

  const handleBackButton = (e) => {
    e.preventDefault();
    appState.goBack();
  };

  return (
    <div
      className={classNames("screen", styles.wrapper, {
        active: appState.currentScreen === "style",
        upcoming: appState.upcomingScreens.indexOf("style") !== -1,
      })}
    >
      <div className="eyebrow">step 2 of 3</div>
      <h2>Choose a style for your card</h2>
      <div className={buttonStyles.buttonsWithNavigationBlock}>
        {appState.theme !== null && (
          <div className={buttonStyles.buttonsWrap}>
            {Object.keys(Cards[appState.theme].styles).map((style) => {
              return (
                <SelectorButton
                  key={style}
                  onClick={(e) => {
                    ReactGA.pageview(`/style/${style}`);
                    appState.setStyle(style);
                  }}
                  title={Cards[appState.theme].styles[style].title}
                  icon={Cards[appState.theme].styles[style].icon}
                  selected={appState.style === style}
                  inactive={appState.style !== null && appState.style !== style}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="buttonsSet">
        <ButtonBack handleClick={() => appState.goBack()} />
        <ButtonNext handleClick={() => appState.goNext()} disabled={appState.style === null} />
      </div>
    </div>
  );
};
