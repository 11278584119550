import styles from "./ChooseExtras.module.scss";
import buttonStyles from "../components/SelectorButton.module.scss";
import { CardsExtras, ScreenSwitchDelay } from "../config/Config";
import { SelectorButton } from "../components/SelectorButton";
import { useAppState } from "../stores/useAppState";
import classNames from "classnames";
import { ButtonBack } from "../components/ButtonBack";
import { ButtonNext } from "../components/ButtonNext";
import ReactGA from "react-ga";

export const ChooseExtras = () => {
  const appState = useAppState();

  const handleBackButton = (e) => {
    e.preventDefault();
    appState.goBack();
  };

  return (
    <div
      className={classNames("screen", styles.wrapper, {
        active: appState.currentScreen === "extra",
        upcoming: appState.upcomingScreens.indexOf("extra") !== -1,
      })}
    >
      <div className="eyebrow">step 3 of 3</div>
      <h2>Add something extra to your card?</h2>
      <div className={buttonStyles.buttonsWithNavigationBlock}>
        <div className={buttonStyles.buttonsWrap}>
          {CardsExtras.map((extra) => {
            return (
              <SelectorButton
                key={extra.title}
                onClick={() => {
                  ReactGA.pageview(`/extra/${extra.code}`);
                  appState.setExtra(extra.title);
                }}
                title={extra.title}
                icon={extra.icon}
                selected={appState.extra === extra.title}
                inactive={appState.extra !== null && appState.extra !== extra.title}
              />
            );
          })}
        </div>
      </div>

      <div className="buttonsSet">
        <ButtonBack handleClick={() => appState.goBack()} />
        <ButtonNext handleClick={() => appState.goNext()} disabled={appState.extra === null} label="Create your custom card" />
      </div>
    </div>
  );
};
