import { useState, useEffect } from "react";
import "./scss/global.scss";
import { Intro } from "./pages/Intro";
import { ChooseTheme } from "./pages/ChooseTheme";
import { ChooseStyle } from "./pages/ChooseStyle";
import { useAppState } from "./stores/useAppState";
import { CreatingCard } from "./pages/CreatingCard";
import { ChooseExtras } from "./pages/ChooseExtras";
import { Results } from "./pages/Results";
import classNames from "classnames";
import { ScrollToTop } from "./utils/utils";

import ReactGA from "react-ga";

function App() {
  const appState = useAppState();

  useEffect(() => {
    appState.setCurrentScreen("intro");
    appState.setIsRefreshing(false);
    ReactGA.initialize("UA-26413426-1");
  }, []);

  useEffect(() => {
    console.log("Scrolling to top");
    ScrollToTop();
  }, [appState.currentScreen]);

  return (
    <div className={classNames("app-wrapper", { refreshing: appState.isRefreshing })}>
      <Intro />
      <ChooseTheme />
      <ChooseStyle />
      <ChooseExtras />
      <CreatingCard />
      <Results />

      {/*<div style={{ position: "absolute", bottom: 0, left: 0 }}>*/}
      {/*  Debug: {appState.theme} / {appState.style} / {appState.extra} / cur: {appState.currentScreen}*/}
      {/*  <div>*/}
      {/*    {JSON.stringify(appState.previousScreens)} => {JSON.stringify(appState.upcomingScreens)}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default App;
