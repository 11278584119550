import classNames from "classnames";
import { useAppState } from "../stores/useAppState";
import { useEffect, useState } from "react";
import { ReactComponent as LoaderLogo } from "../assets/cbloader.svg";
import styles from "./CreatingCard.module.scss";

export const CreatingCard = () => {
  const appState = useAppState();

  const [isVisible, setIsVisible] = useState(false);
  const [isUberhidden, setIsUberhidden] = useState(false);

  useEffect(() => {
    if (appState.currentScreen === "creating") {
      console.log("Creating screen working…");
      setTimeout(() => {
        setIsUberhidden(false);
        setIsVisible(true);
      }, 0);

      setTimeout(() => {
        setIsUberhidden(true);
      }, 3500);

      setTimeout(() => {
        setIsVisible(false);
        appState.goNext();
      }, 4000);
    }
  }, [appState, appState.currentScreen]);

  return (
    <div
      className={classNames("screen", styles.wrapper, {
        active: appState.currentScreen === "creating",
        upcoming: appState.upcomingScreens.indexOf("creating") !== -1,
      })}
    >
      <div className="eyebrow">Get Ready!</div>
      <h2>We are now creating your card</h2>

      <div className={classNames(styles.loading, { opacityHidden: !isVisible, uberHidden: isUberhidden })}>
        <LoaderLogo />
      </div>
      <div className="eyebrow">Generating…</div>
    </div>
  );
};
