//zustand store
import create from "zustand";
import ReactGA from "react-ga";

export const useAppState = create((set, get) => ({
  style: null,
  theme: null,
  extra: null,
  currentScreen: "intro",
  isRefreshing: true,

  allScreens: ["intro", "theme", "style", "extra", "creating", "results"],
  upcomingScreens: ["theme", "style", "extra", "creating", "results"],
  previousScreens: [],

  setStyle: (style) => set({ style }),
  setTheme: (theme) => set({ theme }),
  setExtra: (extra) => set({ extra }),
  setCurrentScreen: (screen) => {
    set({ currentScreen: screen });
    console.log(`Tracking: /${screen}`);
    ReactGA.pageview(`/${screen}`);
  },

  goNext: () => {
    const currentScreenIndex = get().allScreens.indexOf(get().currentScreen);
    const targetScreenIndex = currentScreenIndex + 1;

    if (currentScreenIndex < get().allScreens.length - 1) {
      console.log(`Tracking: /${get().allScreens[targetScreenIndex]}`);
      ReactGA.pageview(`/${get().allScreens[targetScreenIndex]}`);

      set({
        currentScreen: get().allScreens[targetScreenIndex],
        previousScreens: get().allScreens.slice(0, targetScreenIndex - 1),
        upcomingScreens: get().allScreens.slice(targetScreenIndex + 1),
      });
    }
  },

  goBack: () => {
    const currentScreenIndex = get().allScreens.indexOf(get().currentScreen);
    const targetScreenIndex = currentScreenIndex - 1;

    if (targetScreenIndex >= 0) {
      console.log(`Tracking: /${get().allScreens[targetScreenIndex]}`);
      ReactGA.pageview(`/${get().allScreens[targetScreenIndex]}`);

      set({
        currentScreen: get().allScreens[targetScreenIndex],
        previousScreens: get().allScreens.slice(0, targetScreenIndex > 0 ? targetScreenIndex : 0),
        upcomingScreens: get().allScreens.slice(targetScreenIndex + 1),
      });
    }
  },

  setIsRefreshing: (isRefreshing) => set({ isRefreshing }),

  reset: () => set({ style: null, theme: null, extra: null, currentScreen: "intro", previousScreens: [], upcomingScreens: get().allScreens }),
}));
