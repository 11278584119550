import classNames from "classnames";
import styles from "../scss/results-screen.scss";
import { useAppState } from "../stores/useAppState";
import { Cards, CardsExtras } from "../config/Config";
import { useEffect, useState } from "react";
import { ButtonBack } from "../components/ButtonBack";
import { CardBuilderScript } from "../config/Config";
import ReactGA from "react-ga";
import app from "../App";

export const Results = () => {
  const appState = useAppState();

  const [aiCardOrder, setAiCardOrder] = useState("left");
  const [cbCardOrder, setCbCardOrder] = useState("right");
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectionClass, setSelectionClass] = useState(null);

  const selectionCompleteClass = "selectionComplete";

  const recordSelection = (selected) => {
    var extraStuff = "";
    switch (appState.extra) {
      case "A cat":
        extraStuff = "cat";
        break;
      case "String lights":
        extraStuff = "sparkes";
        break;
      case "A frame":
        extraStuff = "frame";
      default:
        extraStuff = "nothing";
    }

    var trackPath = `/result/${appState.theme}/${appState.style}/${extraStuff}/${selected}`;
    console.log("Tracking result:", trackPath);
    ReactGA.pageview(trackPath);
  };

  const handleReset = () => {
    appState.setIsRefreshing(true);
    setTimeout(() => {
      appState.reset();
      setSelectedCard(null);
      setSelectionClass(null);

      setTimeout(() => {
        appState.setIsRefreshing(false);
      }, 1000);
    }, 1000);
  };

  useEffect(() => {
    if (Math.random() > 0.5) {
      setAiCardOrder("cardleft");
      setCbCardOrder("cardright");
    } else {
      setAiCardOrder("cardright");
      setCbCardOrder("cardleft");
    }
  }, [appState.currentScreen]);

  useEffect(() => {
    switch (selectedCard) {
      case "ai":
        setSelectionClass("selectionIncorrect");
        break;
      case "cb":
        setSelectionClass("selectionCorrect");
        break;
      default:
        setSelectionClass(null);
    }
  }, [selectedCard]);

  return (
    <div
      className={classNames("screen results-screen", {
        active: appState.currentScreen === "results",
        upcoming: appState.upcomingScreens.indexOf("results") !== -1,
      })}
    >
      {/*<div style={{ position: "absolute", left: 0, top: 0 }}>*/}
      {/*  <a*/}
      {/*    onClick={(e) => {*/}
      {/*      e.preventDefault();*/}
      {/*      setSelectedCard(null);*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    DEBUG: Changed my mind!*/}
      {/*  </a>*/}
      {/*</div>*/}
      <div className={classNames("titleHolder", { "showing-results": selectedCard !== null })}>
        <div className="initial">
          <div className="eyebrow">Here are your results</div>
          <h2>Which card was created by Cloudberry?</h2>
        </div>
        <div className="resulting">
          {selectedCard === "cb" && (
            <>
              <div className="eyebrow">Great!</div>
              <h2>You have good taste.</h2>
            </>
          )}
          {selectedCard === "ai" && (
            <>
              <div className="eyebrow">Ouch!</div>
              <h2>Whomp whomp.</h2>
            </>
          )}
        </div>
      </div>
      {appState.theme && appState.style && (
        <>
          <div className={classNames("cardsWrapper", selectionClass, selectionClass ? selectionCompleteClass : null)}>
            <div className="card dummy" tabIndex="-1">
              <img src={Cards[appState.theme].styles[appState.style].image_cb.url} />
            </div>

            <button
              className={classNames("card", cbCardOrder, { chosen: selectedCard === "cb" }, { unchosen: selectedCard === "ai" })}
              onClick={(e) => {
                e.preventDefault();
                setSelectedCard("cb");
                recordSelection("cb");
              }}
            >
              <div className="content">
                <img src={Cards[appState.theme].styles[appState.style].image_cb.url} />
                {appState.extra && (
                  <img
                    className="extra"
                    src={
                      CardsExtras.filter((item) => {
                        return item.title === appState.extra;
                      })[0].image_url
                    }
                  />
                )}
              </div>
            </button>
            <button
              className={classNames("card", aiCardOrder, { chosen: selectedCard === "ai" }, { unchosen: selectedCard === "cb" })}
              onClick={(e) => {
                e.preventDefault();
                setSelectedCard("ai");
                recordSelection("ai");
              }}
            >
              <div className="content">
                <img src={Cards[appState.theme].styles[appState.style].image_ai.url} />
                {appState.extra && (
                  <img
                    className="extra"
                    src={
                      CardsExtras.filter((item) => {
                        return item.title === appState.extra;
                      })[0].image_url
                    }
                  />
                )}
              </div>
            </button>
          </div>

          <div className={classNames("results-footer", { "showing-results": selectedCard !== null })}>
            <div className="caption">
              {selectedCard === "ai"
                ? Cards[appState.theme].styles[appState.style].image_ai.description
                : Cards[appState.theme].styles[appState.style].image_cb.description}
            </div>

            <p>
              Cloudberry creates great, human-centered design experiences with a team of smart, empathetic people. Something no AI can replace.
              <br />
              <a href="https://cloudberrycreative.com" target="_blank">
                Learn more about us here.
              </a>
            </p>

            <div className="buttonsSet canStack">
              <button
                className="btn btn-download"
                onClick={() => {
                  ReactGA.pageview("/download");
                  var extraStuff = "";
                  switch (appState.extra) {
                    case "A cat":
                      extraStuff = "cat";
                      break;
                    case "String lights":
                      extraStuff = "sparkes";
                      break;
                    case "A frame":
                      extraStuff = "frame";
                    default:
                      extraStuff = "";
                  }
                  const urlToOpen = `${CardBuilderScript}?imageTheme=${appState.theme}&imageMake=${appState.style}&imageVariation=${selectedCard}&imageExtra=${extraStuff}`;
                  console.log("URL to open:", urlToOpen);
                  setTimeout(() => {
                    document.location.href = urlToOpen;
                  }, 200);
                }}
              >
                Download your custom card
              </button>

              <button
                className="btn btn-refresh"
                onClick={(e) => {
                  ReactGA.pageview("/reset");
                  handleReset();
                }}
              >
                Try again
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
