import styles from "./Intro.module.scss";
import { ReactComponent as Logo } from "../assets/cb-logo.svg";
import classNames from "classnames";
import { useAppState } from "../stores/useAppState";

export const Intro = () => {
  const appState = useAppState();

  return (
    <div
      className={classNames("screen", styles.wrapper, {
        active: appState.currentScreen === "intro",
        upcoming: appState.upcomingScreens.indexOf("intro") !== -1,
      })}
    >
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.presents}>Cloudberry presents</div>
      <h1>The Great Holiday Art-off!</h1>
      <p>
        Can you tell an AI-generated holiday card from one made by the Cloudberry Creative team? Choose a few key words to get your custom card, and guess which
        one was made by Cloudberry.
      </p>
      <p>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            appState.goNext();
          }}
        >
          Create your custom card
        </button>
      </p>
    </div>
  );
};
