import IconNYC from "../assets/icons/theme-ny.svg";
import IconAnimals from "../assets/icons/theme-animals.svg";
import IconTreats from "../assets/icons/theme-treats.svg";

import IconRetro from "../assets/icons/theme-retro.svg";
import IconHandmade from "../assets/icons/theme-handmade.svg";
import IconModern from "../assets/icons/theme-modern.svg";

import IconCat from "../assets/icons/extra-cat.svg";
import IconSparkles from "../assets/icons/extra-sparkles.svg";
import IconFrame from "../assets/icons/extra-frame.svg";
import IconNone from "../assets/icons/extra-none.svg";

export const ScreenSwitchDelay = 500;

export const CardBuilderScript = "https://2022.cloudberrycreative.com/card-builder/card.php";

export const Cards = {
  nyc: {
    title: "Winter in NYC",
    icon: IconNYC,
    styles: {
      retro: {
        icon: IconRetro,
        title: "Retro '60s card",
        image_cb: {
          url: require("../assets/cards/nyc/retro-cb.png"),
          description: "Made by Lily, a Senior Graphic Designer at CB, who will spend the holidays cooking with her rescue pup Sukie.",
          alt: "Yellow NYC cab in front of starry New York City silhouette.",
        },
        image_ai: {
          url: require("../assets/cards/nyc/retro-ai.png"),
          description: "This card was created by an AI-art generator.",
          alt: "Snow over New York City buidlings with people out shopping.",
        },
      },
      handmade: {
        icon: IconHandmade,
        title: "Whimsical hand made",
        image_cb: {
          url: require("../assets/cards/nyc/handmade-cb.png"),
          description:
            "Made by Jenna, Senior Project Manager at Cloudberry. Her hometown gathers at midnight on Christmas Day to sing holiday tunes through the morning.",
          alt: "Collage with yellow NYC cab, empire state buidling, snowman and atmospheric Christmas lights.",
        },
        image_ai: {
          url: require("../assets/cards/nyc/handmade-ai.png"),
          description: "This card was created by an AI-art generator.",
          alt: "Snowy day with black and white paper cut outs of New York City buildings and park trees.",
        },
      },
      modern: {
        icon: IconModern,
        title: "Modern and graphic",
        image_cb: {
          url: require("../assets/cards/nyc/modern-cb.png"),
          description:
            "Made by Yvette, Director of Operations at Cloudberry. Yvette loves going for a winter run while the smells of clove, cinnamon, homemade fudge and oranges fill the house.",
          alt: "New York City building and subway in background, man with Chrysler building tattoo with a bow around it on his arm.",
        },
        image_ai: {
          url: require("../assets/cards/nyc/modern-ai.png"),
          description: "This card was created by an AI-art generator.",
          alt: "Snowy New York City silhoette.",
        },
      },
    },
  },
  animals: {
    title: "Forest Animals",
    icon: IconAnimals,
    styles: {
      retro: {
        icon: IconRetro,
        title: "Retro '60s card",
        image_cb: {
          url: require("../assets/cards/animals/retro-cb.png"),
          description: "Made by Lily, Senior Visual Designer at Cloudberry, who will spend the holidays cooking with her rescue pup Sukie.",
          alt: "Starry retro style fox, bunny and owl",
        },
        image_ai: {
          url: require("../assets/cards/animals/retro-ai.png"),
          description: "This card was created by an AI-art generator.",
          alt: "Retro stylistic forest with animals in 70s colors.",
        },
      },
      handmade: {
        icon: IconHandmade,
        title: "Whimsical hand made",
        image_cb: {
          url: require("../assets/cards/animals/handmade-cb.png"),
          description: "Made by Lily, Senior Visual Designer at Cloudberry, who will spend the holidays cooking with her rescue pup Sukie.",
          alt: "Snowing over rabbit peaking out from behind a tree in the forest",
        },
        image_ai: {
          url: require("../assets/cards/animals/handmade-ai.png"),
          description: "This card was created by an AI-art generator.",
          alt: "Gathering of animals in winter forest, foxes, deers, racoons etc.",
        },
      },
      modern: {
        icon: IconModern,
        title: "Modern and graphic",
        image_cb: {
          url: require("../assets/cards/animals/modern-cb.png"),
          description:
            "Made by Melia, UX Architect at Cloudberry. Melia loves driving through “Glittering Lights” a decoration and lights display in her hometown of Las Vegas.",
          alt: 'Snowman and animals with cute message "there\'s snowbody like you"',
        },
        image_ai: {
          url: require("../assets/cards/animals/modern-ai.png"),
          description: "This card was created by an AI-art generator.",
          alt: "Gathering of animals in winter forest with black and white christmas trees, sad fox, deers, racoons etc.",
        },
      },
    },
  },
  treats: {
    title: "Holiday Treats",
    icon: IconTreats,
    styles: {
      retro: {
        icon: IconRetro,
        title: "Retro '60s card",
        image_cb: {
          url: require("../assets/cards/treats/retro-cb.png"),
          description: "Made by Jane, UX Architect at Cloudberry. Jane's favorite holiday treat is Chess Pie - especially for breakfast!",
          alt: "Retro style cup of hot cocoa with whipped cream, multi-colored stars.",
        },
        image_ai: {
          url: require("../assets/cards/treats/retro-ai.png"),
          description: "This card was created by an AI-art generator.",
          alt: "Retro style lollipop, ice cream, soda",
        },
      },
      handmade: {
        icon: IconHandmade,
        title: "Whimsical hand made",
        image_cb: {
          url: require("../assets/cards/treats/handmade-cb.png"),
          description: "Made by Lily, Senior Visual Designer at Cloudberry, who will spend the holidays cooking with her rescue pup Sukie.",
          alt: "A hand cutting a piece from a cherry pie.",
        },
        image_ai: {
          url: require("../assets/cards/treats/handmade-ai.png"),
          description: "This card was created by an AI-art generator.",
          alt: "Cookies, gingerbread and candy canes on white background.",
        },
      },
      modern: {
        icon: IconModern,
        title: "Modern and graphic",
        image_cb: {
          url: require("../assets/cards/treats/modern-cb.png"),
          description:
            "This card was created by Mirae, Visual Designer at Cloudberry. Mirae loves watching holiday movies with friends and family over a cup of hot chocolate.",
          alt: "Green place mat with dinner plate and silverware with decorated gingerbread cookies. ",
        },
        image_ai: {
          url: require("../assets/cards/treats/modern-ai.png"),
          description: "This card was created by an AI-art generator.",
          alt: "Gingerbread cookies with white icing.",
        },
      },
    },
  },
};

export const CardsExtras = [
  {
    code: "cat",
    title: "A cat",
    icon: IconCat,
    image_url: require("../assets/cards/extra-cat.png"),
  },
  {
    code: "lights",
    title: "String lights",
    icon: IconSparkles,
    image_url: require("../assets/cards/extra-sparkes.png"),
  },
  {
    code: "frame",
    title: "A frame",
    icon: IconFrame,
    image_url: require("../assets/cards/extra-frame.png"),
  },
  {
    code: "nothing",
    title: "Nothing extra!",
    icon: IconNone,
    image_url: null,
  },
];
