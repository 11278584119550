export const ScrollToTop = () => {
  var items = document.getElementsByClassName("screen");
  var elements = Array.from(items);
  elements.forEach((item) => {
    console.log("Scrolling to:", item, item.scrollTop);
    item.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
};
