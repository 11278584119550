import classNames from "classnames";
import styles from "./ChooseTheme.module.scss";
import buttonStyles from "../components/SelectorButton.module.scss";
import { SelectorButton } from "../components/SelectorButton";
import { Cards, ScreenSwitchDelay } from "../config/Config";
import { useAppState } from "../stores/useAppState";
import { ButtonBack } from "../components/ButtonBack";
import { ButtonNext } from "../components/ButtonNext";
import { useEffect } from "react";
import { ScrollToTop } from "../utils/utils";
import ReactGA from "react-ga";

export const ChooseTheme = ({ onBack }) => {
  const appState = useAppState();

  const handleBackButton = (e) => {
    e.preventDefault();
    appState.goBack();
  };

  return (
    <div
      className={classNames("screen", styles.wrapper, {
        active: appState.currentScreen === "theme",
        upcoming: appState.upcomingScreens.indexOf("theme") !== -1,
      })}
    >
      <div className="eyebrow">step 1 of 3</div>
      <h2>Choose a theme for your card</h2>

      <div className={buttonStyles.buttonsWithNavigationBlock}>
        <div className={buttonStyles.buttonsWrap}>
          {Object.keys(Cards).map((theme) => {
            return (
              <SelectorButton
                key={theme}
                onClick={(e) => {
                  ReactGA.pageview(`/theme/${theme}`);
                  appState.setTheme(theme);
                }}
                title={Cards[theme].title}
                icon={Cards[theme].icon}
                selected={appState.theme === theme}
                inactive={appState.theme !== null && appState.theme !== theme}
              />
            );
          })}
        </div>
      </div>
      <div className="buttonsSet">
        <ButtonBack handleClick={() => appState.goBack()} />
        <ButtonNext handleClick={() => appState.goNext()} disabled={appState.theme === null} />
      </div>
    </div>
  );
};
