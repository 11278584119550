import classNames from "classnames";
import styles from "./SelectorButton.module.scss";

export const SelectorButton = ({ icon, title, onClick, selected, inactive }) => {
  return (
    <>
      <button
        onClick={(e) => {
          onClick();
        }}
        className={classNames(selected === true ? styles.selected : null, styles.button, inactive === true ? styles.inactive : null)}
      >
        <div className={styles.btnContent}>
          <img src={icon} className={styles.icon} />
          <div className={styles.title}>{title}</div>
        </div>
      </button>
    </>
  );
};
